<template>
<div v-if="!isPro && show && ad">
	<slot :ad="ad" />
</div>
</template>

<script>
//import axios from 'axios';
export default {
	name: 'Ad',
	props: {
		'type': String,
		'show': Boolean
	},
	data() {
		return {
			ad: false
		};
	},
	async mounted() {
		if (this.type === 'carbon') {
			this.ad =
				await fetch(`${this.apiURL}/ads/carbon?placement=extension`)
				.then(r => r.json())
				.then(ad => {
					this.$gtag.event('ad_render', {
						'event_category': 'carbon_ads',
						'event_label': `Rendered Ad From ${this.ad.company}`,
						'non_interaction': true
					});
					return ad;
				}).catch(e => {
					console.log(e);
					return false;
				});
				console.log(this.ad);
		}
		if (this.type === 'ethical') {
			this.ad =
				await fetch(`${this.apiURL}/ads/ethical?placement=image`)
				.then(r => r.json())
				.then(ad => {
					this.$gtag.event('ad_render', {
						'event_category': 'ethical_ads',
						'event_label': `Rendered Ad From ${ad.copy.headline}`,
						'non_interaction': true
					});
					return ad;
				}).catch(e => {
					console.log(e);
					return false;
				});
		}
	}
}
</script>

<style lang="scss">
.carbon {
	width: 100%;
	max-width: 240px;
	border-color: var(--color-700) !important;
	padding: 1rem;
	font-weight: var(--font-weight-normal);
	font-size: 12px;
	line-height: 1.4;
	.btn {
		height: auto !important;
		line-height: 1.3 !important;
		padding: 0.5rem 0.75rem;
		font-size: 12px;
	}
	&:hover {
		text-decoration: none;
	}
}
</style>
